<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-card-title>
                    Fill out your social links.
                    <span class="font-weight-normal">(Optional)</span>
                  </b-card-title>
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['fas', 'user-circle']" />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="8"
                        order="3"
                        order-lg="2"
                        class="text"
                      >
                        We will only show links on your
                        site if you provide them below.
                      </b-col>
                      <b-col
                        cols="9"
                        lg="3"
                        order="2"
                        order-lg="3"
                        class="button"
                      >
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          v-b-modal.modal-theme-guide
                        >
                          View Example
                          <font-awesome-icon
                            :icon="['far', 'window-maximize']"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>

                  <b-form @submit.prevent="onFormSubmit">
                    <b-row>
                      <b-col md>
                        <b-form-group id="input-group-fb">
                          <ValidationProvider
                            name="Facebook"
                            rules="url"
                            v-slot="validationContext"
                          >
                            <label label-for="input-fb">Facebook</label>
                            <b-form-input
                              id="input-fb"
                              v-model="candidateModel.socialLinks.facebookLink"
                              type="text"
                              :state="getValidationState(validationContext)"
                              placeholder="https://www.facebook.com/username"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col md>
                        <b-form-group id="input-group-twt">
                          <ValidationProvider
                            name="Twitter"
                            rules="url"
                            v-slot="validationContext"
                          >
                            <label label-for="input-twt">Twitter</label>
                            <b-form-input
                              id="input-twt"
                              v-model="candidateModel.socialLinks.twitterLink"
                              type="text"
                              :state="getValidationState(validationContext)"
                              placeholder="https://www.twitter.com/username"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md>
                        <b-form-group
                          id="input-group-inst"
                        >
                          <ValidationProvider
                            name="Instagram"
                            rules="url"
                            v-slot="validationContext"
                          >
                            <label label-for="input-inst">Instagram</label>
                            <b-form-input
                              id="input-inst"
                              v-model="candidateModel.socialLinks.instagramLink"
                              type="text"
                              :state="getValidationState(validationContext)"
                              placeholder="https://www.instagram.com/username"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col md>
                        <b-form-group id="input-group-lnk">
                          <ValidationProvider
                            name="Linkedin"
                            rules="url"
                            v-slot="validationContext"
                          >
                            <label label-for="input-lnk">LinkedIn</label>
                            <b-form-input
                              id="input-lnk"
                              v-model="candidateModel.socialLinks.linkedInLink"
                              type="text"
                              :state="getValidationState(validationContext)"
                              placeholder="https://www.linkedin.com/username"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md>
                        <b-form-group id="input-group-web">
                          <ValidationProvider
                            name="Website"
                            rules="url"
                            v-slot="validationContext"
                          >
                            <label label-for="input-web"
                              >Personal Website</label
                            >
                            <b-form-input
                              id="input-web"
                              v-model="
                                candidateModel.socialLinks.personalWebsiteLink
                              "
                              type="text"
                              :state="getValidationState(validationContext)"
                              placeholder="https://www.yourwebsite.com"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                      <b-col md>
                        <b-form-group id="input-group-faw">
                          <ValidationProvider
                            name="Faw"
                            rules="url"
                            v-slot="validationContext"
                          >
                            <label label-for="input-faw">Find A Writer</label>
                            <b-form-input
                              id="input-faw"
                              v-model="candidateModel.socialLinks.fawLink"
                              type="text"
                              :state="getValidationState(validationContext)"
                              placeholder="https://directories.wga.org/member/memberid"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </ValidationProvider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-button
                      v-show="false"
                      ref="submit"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-skip="onSkip"
                @on-back="onBack"
                @on-preview="onPreview"
                :hideSkipButton="false"
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="socialLinks"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  name: "SocialLinks",
  mixins: [DirtyFlagMixin, CandidateMixin],
  methods: {
    onPreview() {},
    onBack() {
      if (this.candidateModel.medias.length > 0)
        this.$router.push("manage-media");
      else this.$router.push("add-media-block");
    },
    onSkip() {
      this.$router.push("add-event");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        facebookLink: this.candidateModel.socialLinks.facebookLink,
        twitterLink: this.candidateModel.socialLinks.twitterLink,
        instagramLink: this.candidateModel.socialLinks.instagramLink,
        linkedInLink: this.candidateModel.socialLinks.linkedInLink,
        personalWebsiteLink: this.candidateModel.socialLinks
          .personalWebsiteLink,
        fawLink: this.candidateModel.socialLinks.fawLink
      };

      this.isSubmitting = true;
      this.saving = true;

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.candidateService
            .SaveSocialLinksAsync(payload)
            .then(res => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  this.$router.push("add-event");
                }
              }, 500);
            })
            .catch(e => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
